<template>
  <section>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <div class="ml-auto">
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 d-flex flex-wrap justify-content-between">
          <span class="h2 text-secondary">
            <!-- <i :class="$route.meta.iconClass"></i>  -->
            Nouveau cadre d'entreprise</span
          >
          <div>
            <b-button
              @click="createItem"
              type="submit"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer
            </b-button>
          </div>
        </div>
      </div>

      <div class="">
        <!-- inputs -->
        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-8 p-1">
            <div class="col-12 h-100 shdow">
              <div class="row">
                <div class="col-12 d-flex flex-wrap justify-content-between">
                  <p class="h4 text-secondary my-auto mr-auto">
                    Informations générales
                  </p>
                </div>
              </div>
              <hr class="mt-1 mb-3 bg-secondary" />
              <div class="d-flex">
                <div class="col-md-6 col-12 pl-0 pr-1">
                  <b-form-group
                    label-cols-sm="4"
                    label="Libellé :"
                    label-class="font-weight-bold"
                  >
                    <b-form-input
                      type="text"
                      placeholder="Libellé"
                      v-model="newCadre.name"
                      :class="{
                        'is-invalid':
                          $v.newCadre.name.$error && $v.newCadre.name.$dirty,
                      }"
                    ></b-form-input>
                    <!-- ERROR MESSAGES -->
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadre.name.$dirty"
                    >
                      {{
                        !$v.newCadre.name.required
                          ? "Champ obligatoire"
                          : !$v.newCadre.name.minLength
                          ? `Le champ doit contenir au moins ${$v.newCadre.name.$params.minLength.min} caractères.`
                          : ""
                      }}
                    </span>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="4"
                    label="Type cadre :"
                    label-class="font-weight-bold"
                  >
                    <v-select
                      placeholder="Type"
                      type="text"
                      :options="['Pattern', 'Règle', 'Politique', 'Principe']"
                      style="padding-right: 0 !important"
                      v-model="newCadre.typeRefcard"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newCadre.typeRefcard.$error &&
                          $v.newCadre.typeRefcard.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadre.typeRefcard.$dirty"
                    >
                      {{
                        !$v.newCadre.typeRefcard.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="4"
                    label="Statut :"
                    label-class="font-weight-bold"
                  >
                    <v-select
                      type="text"
                      :options="['Validé', 'Deployé']"
                      style="padding-right: 0 !important"
                      placeholder="Statut"
                      v-model="newCadre.status"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newCadre.status.$error &&
                          $v.newCadre.status.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadre.status.$dirty"
                    >
                      {{
                        !$v.newCadre.status.required ? "Champ obligatoire" : ""
                      }}
                    </span>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="4"
                    label="Rédacteur :"
                    label-class="font-weight-bold"
                  >
                    <v-select
                      placeholder="Rédacteur"
                      label="text"
                      :reduce="(user) => user.value"
                      :options="responsables"
                      v-model="newCadre.redacteur"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newCadre.redacteur.$error &&
                          $v.newCadre.redacteur.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadre.redacteur.$dirty"
                    >
                      {{
                        !$v.newCadre.redacteur.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </b-form-group>
                </div>
                <div class="col-md-6 col-12 pl-1 pr-0">
                  <b-form-group
                    label-cols-sm="4"
                    label="Responsable :"
                    label-class="font-weight-bold"
                  >
                    <v-select
                      placeholder="Responsable"
                      label="text"
                      :reduce="(user) => user.value"
                      :options="responsables"
                      v-model="newCadre.responsable"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newCadre.responsable.$error &&
                          $v.newCadre.responsable.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadre.responsable.$dirty"
                    >
                      {{
                        !$v.newCadre.responsable.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="4"
                    label="Principe :"
                    label-class="font-weight-bold"
                  >
                    <v-select
                      placeholder="Principe"
                      label="text"
                      :reduce="(user) => user.value"
                      :options="principeList"
                      v-model="newCadre.principe"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newCadre.principe.$error &&
                          $v.newCadre.principe.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newCadre.principe.$dirty"
                    >
                      {{
                        !$v.newCadre.principe.required
                          ? "Champ obligatoire"
                          : ""
                      }}
                    </span>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="4"
                    label="Description :"
                    label-class="font-weight-bold"
                  >
                    <b-form-textarea
                      rows="5"
                      v-model="newCadre.description"
                      placeholder="Description"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 p-1">
            <div class="col-12 shdow h-100">
              <div class="row">
                <div class="col-12 d-flex flex-wrap justify-content-between">
                  <p class="h4 text-secondary my-auto mr-auto">Documents</p>
                </div>
              </div>
              <hr class="mt-1 mb-3 bg-secondary" />

              <FilePicker
                @change="filePickerChanger"
                class="font-weight-normal"
              ></FilePicker>
            </div>
          </div>
        </div>
        <!-- end inputs -->
      </div>

      <!-- End Tabs -->
      <hr style="margin-top: 30px" />

      <div class="row my-5">
        <div class="col-12" style="margin-top: -20px">
          <b-tabs class="tab-solid tab-solid-primary mt-3">
            <b-tab title="Capacités associées">
              <TechnicalCapacityTable :disabled="true" />
            </b-tab>
            <b-tab title="Système d’application">
              <SystemeTable :disabled="true" />
            </b-tab>
            <b-tab title="Cadres associés">
              <sous-cadrstable :disabled="true" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
// import ModalActions from "@/components/custom/ModalActions.vue";

//import Cadres from "./components/Cadres.vue";
//import Implementation from "./components/Implementations.vue";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import ModalActions from "@/components/custom/ModalActions.vue";
import Swal from "sweetalert2";
import TechnicalCapacityTable from "../components/TechnicalCapacityTable";
import SousCadrstable from "../components/sousCadrstable";
import SystemeTable from "../components/SystemeTable";
import FilePicker from "../../../../components/custom/FilePicker";

export default {
  components: {
    FilePicker,
    ModalActions,
    SystemeTable,
    SousCadrstable,
    TechnicalCapacityTable,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    file1: null,
    active: null,
    newCadre: {
      name: "",
      description: "",
      code: "",
      status: "",
      redacteur: "",
      capaciteTechnique: "",
      contributor: "",
      responsable: "",
      type: "",
      applications: [],
      typeSystem: "",
      systemAplication: "",
      typeRefcard: "",
      file: "",
      niveau: "",
      principe: "",
    },
    cadre: {
      items: [
        {
          type: "Regles",
          code: 56,
          libelle: "Api station",
          couverture: 30,
          status: "Production",
          capacite: "Services d'echanges",
          implementaion: 4,
          livrable: 2,
          actions: ["edit", "delete", "show"],
        },
      ],
      fields: [
        { key: "type", label: "Type cadre", sortable: true },
        { key: "code", label: "Code", sortable: true },
        { key: "libelle", label: "Libellé", sortable: true },
        { key: "couverture", label: "Couverture projet", sortable: true },
        { key: "capacite", label: "Capacite technique", sortable: true },
        { key: "implementaion", label: "Implementaion", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "livrable", label: "Livrables", sortable: true },
        { key: "actions", label: "" },
      ],
    },
    impl: {
      items: [
        {
          type: "Composant Technique",
          libelle: "OVH",
          po: "O. GUISSE",
          capacite: "Cloud",
          patrimoine: 4,
          actions: ["edit", "delete", "show"],
        },
      ],
      fields: [
        { key: "type", label: "Type object", sortable: true },
        { key: "libelle", label: "Libellé object", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "capacite", label: "Capacite", sortable: true },
        { key: "patrimoine", label: "Implementaion", sortable: true },
        { key: "actions", label: "" },
      ],
    },
  }),
  validations: {
    newCadre: {
      name: {
        required,
        minLength: minLength(5),
      },
      redacteur: {
        required,
      },
      typeRefcard: {
        required,
      },
      status: {
        required,
      },
      responsable: {
        required,
      },
      principe: {
        required,
      },
    },
  },
  created() {
    this.$store.dispatch("capaciteTechnique/fetchAllCapacites");
    this.$store.dispatch("application/fetchAllApplications");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("principe/fetchAllPrincipes");
  },
  methods: {
    filePickerChanger(files) {
      this.file1 = files;
    },
    handleFileUpload(event) {
      var file = [];
      for (var i = 0; i < event.target.files.length; i++) {
        file.push(event.target.files[i]);
      }
      this.file1 = file;
    },
    createItem() {
      this.$v.$touch();
      var data = {
        name: this.newCadre.name,
        redacteur: this.newCadre.redacteur,
        description: this.newCadre.description,
        // // application : this.newCadre.applications ? this.newCadre.applications.map((data) => data.value) : [],
        // capaciteTechnique : this.newCadre.capaciteTechnique,
        // typeSysteme : this.newCadre.typeSystem,
        // systemesApplication : this.newCadre.systemAplication,
        type: this.newCadre.typeRefcard,
        status: this.newCadre.status,
        responsable: this.newCadre.responsable,
        principe: this.newCadre.principe,
      };
      if (!this.$v.$invalid) {
        this.$store.dispatch("cadre/createCadre", data).then(() => {
          if (this.file1) {
            this.file1.map((data) => {
              data.append("cadre", this.CADRE_ENTREPRISE["@id"]);
              this.$store
                .dispatch("fileCadrs/createCadrsFile", data)
                .then(() => {});
            });
          }
          Swal.fire({
            title: "Le cadre d'entreprise est bien créé !",
            type: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.$router.push({
                name: "show-principe-ref",
                params: {
                  slug: this.CADRE_ENTREPRISE.slug,
                  id: this.CADRE_ENTREPRISE.id,
                  fromCreation: true,
                },
              });
            }
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters("capaciteTechnique", ["CAPACITES_TECHNIQUES"]),
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("cadre", ["CADRE_ENTREPRISE"]),
    ...mapGetters("principe", ["PRINCIPES"]),

    capaciteRatechements() {
      return this.CAPACITES_TECHNIQUES.map((data) => ({
        ...data,
        text: data.name,
      }));
    },
    responsables() {
      return this.RESPONSABLES.map((data) => ({
        ...data,
        text: data.firstname + " " + data.lastname,
        value: `api/users/${data.id}`,
      }));
    },
    principeList() {
      return this.PRINCIPES.map((data) => ({
        ...data,
        text: data.name,
        value: data["@id"],
      }));
    },
    applicationsList() {
      return this.APPLICATIONS.map((data) => ({
        ...data,
        value: data["@id"],
      }));
    },
  },
};
</script>
